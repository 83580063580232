import React from "react"
import styled from "styled-components"

function InputComponent(props) {
  const [details, setDetails] = React.useState({
    Fname: "",
    email: "",
    purpose: props.purpose,
    webhook: props.webhook,
    price: props.price,
    country: "India",
    vat_number: "",
    company: "",
  })
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState("")
  console.log("redirectUrl", props.redirectUrl)

  // gst and transction
  const transactionFee = props.price * 0.05 + 3
  const gstFee = transactionFee * 0.18
  const price = parseFloat(props.price)
  const gst = price * (18 / 100)
  const totalPrice = parseFloat(props.price) + gst


  const handleSubmit = async (e) => {
    e.preventDefault()
    if (details.Fname === "") {
      setError("Name is required field!")
      setLoading(false)
    }
    if (details.email === "") {
      setError("Email is required")
      setLoading(false)
    }
    if (details.Fname !== "" && details.email !== "") {
      setLoading(true)
    }
    try {
      const res = await fetch(
        `${process.env.STRAPI_API_URL}/data-tab-tests/pay`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            buyer_name: details.Fname,
            email: details.email,
            webhook: details.webhook || props.webhook,
            amount: Math.round(totalPrice),
            purpose: details.purpose || props.purpose,
            redirect_url: props.redirectUrl,
          }),
        }
      )
      const data = await res.json()
      if (data) {
        window.location.href = data.payment_request.longurl
        setLoading(false)
      }
    } catch (err) {}
  }
  return (
    <Wrapper>
      <span
        style={{
          color: "#000",
          fontStyle: "italic",
          fontSize: "0.75rem",
          fontWeight: "500",
        }}
      >
        {error}
      </span>
      <div className="search__container">
        <form type="submit" style={{ width: "100%" }}>
          <div className="input_wrapper">
            <input
              className="search__input"
              type="text"
              placeholder="name"
              value={details.Fname}
              required
              onChange={(e) =>
                setDetails({
                  ...details,
                  Fname: e.target.value,
                })
              }
            />
          </div>
          <div className="input_wrapper">
            <input
              className="search__input"
              type="text"
              placeholder="working email address"
              value={details.email}
              required
              onChange={(e) =>
                setDetails({
                  ...details,
                  email: e.target.value,
                })
              }
            />
          </div>
          <div className="input_wrapper">
            <input
              className="search__input"
              type="text"
              placeholder="country"
              value={details.country}
              onChange={(e) =>
                setDetails({
                  ...details,
                  country: e.target.value,
                })
              }
            />
          </div>
          <div className="input_wrapper">
            <input
              className="search__input"
              type="text"
              placeholder="company name (optional)"
              value={details.company}
              onChange={(e) =>
                setDetails({
                  ...details,
                  company: e.target.value,
                })
              }
            />
          </div>
          <div className="gst_wrapper">
            <div className="transction_fee">
              <div>
                <h4>Price : </h4>
                <span>
                  {price.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })}
                </span>
              </div>

              <div>
                <h4>18% GST :</h4>
                <span>{gst.toFixed(2)}</span>
              </div>
            </div>
          </div>
          {/* <div className="input_wrapper">
            <input
              className="search__input"
              type="text"
              placeholder="VAT number (optional)"
              value={details.vat_number}
              onChange={(e) =>
                setDetails({
                  ...details,
                  vat_number: e.target.value,
                })
              }
            />
          </div> */}
          <div className="button-wrapper">
            <button className="submit" type="submit" onClick={handleSubmit}>
              {!loading ? `Pay  ${Math.round(totalPrice)} ₹` : `Loading...`}
            </button>
          </div>
        </form>
      </div>
      <div className="info">
        <h2 style={{ fontSize: "0.85rem", color: "#ffffff !important" }}>
          <span style={{ color: "#000", fontWeight: "700", fontSize: "1rem" }}>
            Info:{" "}
          </span>{" "}
          {` `}After the payment process is successful you will automatically
          recieve access data for DATATab
        </h2>
      </div>
    </Wrapper>
  )
}

export default InputComponent

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1rem;
  flex-direction: column;

  .search__container {
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    width: 100%;
    .search__input {
      width: 100%;
      padding: 20px 10px;
      border: none;
      background-color: transparent;
      transition: transform 250ms ease-in-out;
      font-size: 14px;
      line-height: 18px;
      outline: none;
      color: var(--secondaryColor);
      font-family: var(--family);
      backface-visibility: hidden;
    }
    .search_icon {
      display: flex;
      width: 60px;
      background-color: none;
      justify-content: center;
      align-items: center;
      border-radius: 69.3284px;

      svg {
        color: #000;
        font-size: 30px;
      }
    }
    .input_wrapper {
      margin: 1rem 0;
      background: #f8f8f8;
    }
    .search__input::placeholder {
      color: rgba(87, 87, 86, 0.8) !important;
      text-transform: lowercase;
      letter-spacing: 1.5px;
    }
    .gst_wrapper {
      display: flex;
      flex-direction: column;
      .transction_fee {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;

        div {
          grid-area: auto/1/auto/2;
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media (max-width: 479px) {
            grid-area: auto/1/auto/4;
          }

          h4 {
            margin: 0.7rem 0;
            font-weight: 600;
          }
        }
      }
    }
    .button-wrapper {
      margin-top: 2rem;
      .submit {
        padding: 15px 22px;
        color: #fff;
        min-width: 150px;
        background-color: #111111;
        border-color: #018786;
        cursor: pointer;
        outline: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid #0000;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 0.25rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
      .submit:hover {
        background-color: #fff;
        color: #111111;
      }
    }
  }
`
